export const validationRules: Record<string, (v: string) => string | boolean> = {
  required: v => !!v || 'É necessário preencher esse campo',
  email: v => validateEmail(v) || 'Insira um e-mail válido',
  password: v =>
    v.length > 5 || 'A senha precisa ter no mínimo 6 dígitos',
}

function validateEmail(email: string) {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
